<template>
    <ValidationObserver ref="validator" slim>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-toolbar flat>
                <v-toolbar-title>{{ $t('src\\views\\bloggerresources\\edit.7948') }}</v-toolbar-title>
            </v-toolbar>
            <v-text-field
                v-model="entity.title"
                :label="$t('src\\views\\bloggerresources\\edit.8032')"
                outlined
                class="ma-1"
            />
            <div v-if="entity.file && entity.file !== ''" class="mb-4">
                <v-icon color="primary">mdi-file</v-icon> {{fileName(entity.file)}}
            </div>
            <v-file-input
                :label="$t('src\\views\\bloggerresources\\edit.8956')"
                outlined
                prepend-icon="mdi-file"
                @change="setDocument($event, index)"
            />
            <v-textarea
                v-model="entity.comment"
                :label="$t('src\\views\\bloggerresources\\edit.2989')"
                outlined
            />
            <v-row
                align="center"
                justify="space-between"
                class="mx-0">
                <v-btn
                    :disabled="!valid"
                    color="primary"
                    @click="save">{{ $t('src\\views\\bloggerresources\\edit.3520') }}</v-btn>
                <v-btn
                    v-if="entity._id"
                    color="error"
                    @click="remove">{{ $t('src\\views\\bloggerresources\\edit.2152') }}</v-btn>
            </v-row>
        </v-form>
    </ValidationObserver>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { fileName } from '../../helpers';

    export default {
        name: 'BloggerResourcesEdit',
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        data: () => ({
            fileName,
            valid: true
        }),
        computed: {
            ...mapState('bloggerResources', ['entity']),
        },
        async mounted() {
            if(this.$route.params.id) {
                await store.dispatch('bloggerResources/get', { id: this.$route.params.id });
            } else {
                await store.commit('bloggerResources/CLEAR_ENTITY');
            }
        },
        methods: {
            async setDocument(file, index) {
                const document = await store.dispatch('files/upload', { file });
                this.entity.file = document;
            },
            async save() {
                this.valid = await this.$refs.validator.validate();
                if(this.valid){
                    await store.commit('bloggerResources/SET_ENTITY', this.entity);
                    await store.dispatch('bloggerResources/save');
                    await this.$router.push({ name: 'blogger-resources-list' });
                }
            },
            async remove() {
                await store.dispatch('bloggerResources/delete', { id: this.entity._id });
                await this.$router.push({ name: 'blogger-resources-list' });
            }
        }
    }
</script>

<style lang="scss">
    .v-image {
        width: 300px;
        margin: 0 auto 20px;
        border-radius: 6px;
    }
</style>
